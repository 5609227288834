<template>
  <div>
    <b-container v-if="products.length > 0">
      <b-row class="mb-3 pt-3 d-flex justify-content-between"><span></span>
        <dropdown-btn @select="getSelected"/>
      </b-row>
      <b-row id="offers-list" class="mb-5" v-if="products.length > 0">
        <b-col lg="4" v-for="(product, key) in products" :key="key">
          <product-card :product="product.product"/>
        </b-col>
      </b-row>
      <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
            class="curva-pagination"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @input="getWishlist"
        ></b-pagination>
      </b-col>
    </b-row>
    </b-container>
    <div class="iq-style4 order-container curva-list" v-else>
      <h5 class="p-2 text-center text-white">{{ $t('profile.noWishlist') }}</h5></div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import DropdownBtn from '@/components/core/dropdown/dropdown-btn'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  components: { DropdownBtn },
  data () {
    return {
      products: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1
    }
  },
  methods: {
    getWishlist () {
      ProfileServices.getWishlist(this.currentPage).then(res => {
        window.scrollTo(0, 0)
        this.rows = res.data.meta.total
        this.perPage = res.data.meta.per_page
        this.currentPage = res.data.meta.current_page
        this.products = res.data.data
      })
    },
    getSelected (value) {
      this.currentPage = 1
      this.sortValue = value
      this.getWishlist()
    }
  },
  created () {
    this.getWishlist()
  },
  mounted () {
    core.index()
  }
}
</script>
